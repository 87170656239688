/**
 * Created by neo on 18.01.2024
 */
import { Audited, AuditedJson } from '../../Audited';
import { observable } from 'mobx';
import { HttpBackend } from '../../../Services/Http/HttpBackend';

export type RouteChallengePointsTransactionJson = AuditedJson & {
  memberId: string;
  challengeId: string;
  customerId: string;
  athleteId: string;
  teamId: string;
  gymId: string;
  type: string;
  points: number;
  totalCollectedPoints: number;
  transactionDateTime: string;
};

export class RouteChallengePointsTransaction extends Audited {
  @observable
  memberId: string = '';
  @observable
  challengeId: string = '';
  @observable
  customerId: string = '';
  @observable
  athleteId: string = '';
  @observable
  teamId: string = '';
  @observable
  gymId: string = '';
  @observable
  type: string = '';
  @observable
  points: number = 0;
  @observable
  totalCollectedPoints: number = 0;
  @observable
  transactionDateTime = new Date();

  constructor(json?: Partial<RouteChallengePointsTransactionJson>) {
    super(json);
    if (json) {
      this.memberId = json.memberId ?? '';
      this.challengeId = json.challengeId ?? '';
      this.customerId = json.customerId ?? '';
      this.athleteId = json.athleteId ?? '';
      this.teamId = json.teamId ?? '';
      this.gymId = json.gymId ?? '';
      this.type = json.type ?? '';
      this.points = json.points ?? 0;
      this.totalCollectedPoints = json.totalCollectedPoints ?? 0;
      this.transactionDateTime = json.transactionDateTime ? new Date(json.transactionDateTime) : new Date();
    }
  }

  static find(teamId: string, memberId: string, params?: any): Promise<RouteChallengePointsTransaction[]> {
    return HttpBackend.get(
      `/coach-program/engagement/route-challenges/teams/admin/${teamId}/members/${memberId}/transactions`,
      params,
    ).then((res) => res.map((r) => new RouteChallengePointsTransaction(r)));
  }
}
