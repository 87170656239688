/**
 * Created by neo on 13.11.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { RouteChallenge } from '../../../../../Model/Engagement/RouteChallenge/RouteChallenge';
import { Col, Row } from 'reactstrap';
import { Button, DatePicker, Form, InputNumber, Select } from 'antd';
import AsyncSelect from 'react-select/async';
import dayjs from 'dayjs';
import { Gym } from '../../../../../Model/Gym/Gym';
import { reaction, runInAction } from 'mobx';
import { EarnModelTemplate } from '../../../../../Model/Engagement/EarnModelTemplate';

export type RouteChallengeParametersProps = {
  challenge: RouteChallenge;
  earnModel?: EarnModelTemplate;
};

export const RouteChallengeParameters: React.FC<RouteChallengeParametersProps> = observer(
  ({ challenge, earnModel }) => {
    const [gym, setGym] = useState<Gym | undefined>();

    const [challengeDifficulty, setChallengeDifficulty] = useState<string>(
      challenge.difficultyFactor === 0.3
        ? 'easy'
        : challenge.difficultyFactor === 0.5
          ? 'medium'
          : challenge.difficultyFactor === 0.7
            ? 'hard'
            : 'custom',
    );

    const calculationEnabled = dayjs(challenge.startDateTime).isAfter(dayjs());
    const totalPossiblePointsPerDay =
      earnModel?.categories.flatMap((c) => c.rules).reduce((acc, em) => acc + em.maxPointsPerActivation, 0) ?? 0;
    const numberOfDays = dayjs.duration(dayjs(challenge.endDateTime).diff(challenge.startDateTime)).asDays();
    const locationsCount = challenge.locations.length - 1;
    const pointsPerLocation =
      locationsCount > 0
        ? Math.floor(
            (challenge.difficultyFactor * totalPossiblePointsPerDay * numberOfDays * challenge.maxMembersPerTeam) /
              locationsCount,
          )
        : 0;
    const recalculationRequired =
      pointsPerLocation > 0 &&
      challenge.locations.some((l, index) => (index === 0 ? false : l.pointsRequired !== pointsPerLocation));

    useEffect(
      () =>
        reaction(
          () => challenge.gymId,
          (gymId) => {
            setGym(undefined);
            if (gymId) {
              Gym.get(gymId).then((res) => setGym(res));
            }
          },
          { fireImmediately: true },
        ),
      [challenge],
    );

    const fetchGym = React.useCallback((query: string) => Gym.find({ query }), []);

    const optionLabel = React.useCallback((obj) => obj.name, []);

    const optionValue = React.useCallback((obj) => obj, []);

    const handleChangeObject = React.useCallback(
      (value?: Gym) => {
        runInAction(() => (challenge.gymId = value?.id));
      },
      [challenge],
    );

    const handleChangeStartDateTime = React.useCallback(
      (date) => {
        const newDate = (date ?? dayjs().add(1, 'month')).startOf('day').toDate();
        runInAction(() => (challenge.startDateTime = newDate));
      },
      [challenge],
    );

    const handleChangeEndDateTime = React.useCallback(
      (date) => {
        const newDate = (date ?? dayjs(challenge.startDateTime).add(1, 'month')).endOf('day').toDate();
        runInAction(() => (challenge.endDateTime = newDate));
      },
      [challenge],
    );

    const handleChangeDisplayStartDateTime = React.useCallback(
      (date) => {
        const newDate = (date ?? dayjs(challenge.startDateTime).subtract(1, 'month')).startOf('day').toDate();
        runInAction(() => (challenge.displayStartDateTime = newDate));
      },
      [challenge],
    );

    const handleChangeDisplayEndDateTime = React.useCallback(
      (date) => {
        const newDate = (date ?? dayjs(challenge.endDateTime).add(1, 'week')).endOf('day').toDate();
        runInAction(() => (challenge.displayEndDateTime = newDate));
      },
      [challenge],
    );

    const handleChangeDifficulty = React.useCallback(
      (newValue) => {
        setChallengeDifficulty(newValue);
        if (newValue === 'easy') {
          runInAction(() => (challenge.difficultyFactor = 0.3));
        } else if (newValue === 'medium') {
          runInAction(() => (challenge.difficultyFactor = 0.5));
        } else if (newValue === 'hard') {
          runInAction(() => (challenge.difficultyFactor = 0.7));
        }
      },
      [challenge],
    );

    const handleChangeDifficultyFactor = React.useCallback(
      (value) => {
        runInAction(() => (challenge.difficultyFactor = value));
      },
      [challenge],
    );

    const handleRecalculatePoints = React.useCallback(() => {
      if (pointsPerLocation) {
        let totalPoints = 0;
        challenge.locations.forEach((location, index) => {
          if (index > 0) {
            location.pointsRequired = pointsPerLocation;
            totalPoints += location.pointsRequired;
            location.totalPointsRequired = totalPoints;
          } else {
            location.pointsRequired = 0;
            location.totalPointsRequired = 0;
          }
        });
      }
    }, [pointsPerLocation, challenge]);

    return (
      <Row>
        <Col xs={12}>
          <Form.Item label="Gym / Company">
            <AsyncSelect
              value={gym}
              isClearable={true}
              loadOptions={fetchGym as any}
              getOptionLabel={optionLabel}
              getOptionValue={optionValue as any}
              onChange={handleChangeObject as any}
            />
          </Form.Item>
        </Col>
        <Col md={6} xl={3}>
          <Form.Item label="Min Members per Team">
            <InputNumber
              value={challenge.minMembersPerTeam}
              onChange={(v) => (challenge.minMembersPerTeam = Number(v))}
              min={1}
              max={100}
            />
          </Form.Item>
        </Col>
        <Col md={6} xl={3}>
          <Form.Item label="Max Members per Team">
            <InputNumber
              value={challenge.maxMembersPerTeam}
              onChange={(v) => (challenge.maxMembersPerTeam = Number(v))}
              min={1}
              max={100}
            />
          </Form.Item>
        </Col>
        <Col xs={6} xl={3}>
          <Form.Item label="Start Date">
            <DatePicker
              value={dayjs(challenge.startDateTime)}
              onChange={handleChangeStartDateTime}
              disabled={challenge.started}
            />
          </Form.Item>
        </Col>
        <Col xs={6} xl={3}>
          <Form.Item label="End Date">
            <DatePicker value={dayjs(challenge.endDateTime)} onChange={handleChangeEndDateTime} />
          </Form.Item>
        </Col>
        <Col xs={6} xl={3}>
          <Form.Item label="Display Start Date" extra="When should the challenge be visible?">
            <DatePicker value={dayjs(challenge.displayStartDateTime)} onChange={handleChangeDisplayStartDateTime} />
          </Form.Item>
        </Col>
        <Col xs={6} xl={3}>
          <Form.Item label="Display End Date" extra="Until when should the challenge be visible?">
            <DatePicker value={dayjs(challenge.displayEndDateTime)} onChange={handleChangeDisplayEndDateTime} />
          </Form.Item>
        </Col>
        <Col xs={6} xl={3}>
          <Form.Item label="Difficulty">
            <Select value={challengeDifficulty} onChange={handleChangeDifficulty} disabled={!calculationEnabled}>
              <Select.Option value="easy">Easy</Select.Option>
              <Select.Option value="medium">Medium</Select.Option>
              <Select.Option value="hard">Hard</Select.Option>
              <Select.Option value="custom">Custom</Select.Option>
            </Select>
            {recalculationRequired && calculationEnabled ? (
              <Button type="link" onClick={handleRecalculatePoints} disabled={!calculationEnabled}>
                Recalculate
              </Button>
            ) : null}
          </Form.Item>
        </Col>
        <Col xs={6} xl={3}>
          <Form.Item label="Difficulty Factor">
            <InputNumber
              value={challenge.difficultyFactor}
              disabled={challengeDifficulty !== 'custom' || !calculationEnabled}
              onChange={handleChangeDifficultyFactor}
            />
          </Form.Item>
        </Col>
      </Row>
    );
  },
);
