/**
 * Created by neo on 22.11.2023
 */
import { Audited, AuditedJson } from '../../Audited';
import { observable, onBecomeObserved, runInAction } from 'mobx';
import { HttpBackend } from '../../../Services/Http/HttpBackend';
import { Pageable } from '../../Pageable';
import { Media, MediaJson } from '../../Media/Media';
import { RouteChallengeTeam } from './RouteChallengeTeam';
import { RouteChallenge } from './RouteChallenge';
import { Athlete } from '../../Athlete/Athlete';

export type RouteChallengeTeamMemberRequest = Pageable & {
  gymId?: string;
  challengeId?: string;
  athleteId?: string;
};

export type RouteChallengeTeamMemberJson = AuditedJson & {
  teamId: string;
  challengeId: string;
  gymId: string;
  customerId: string;
  athleteId: string;
  isAdmin: boolean;
  username: string;
  profileImage?: MediaJson;
  totalCollectedPoints: number;
};

export class RouteChallengeTeamMember extends Audited {
  @observable
  teamId: string = '';
  @observable
  challengeId: string = '';
  @observable
  gymId: string = '';
  @observable
  customerId: string = '';
  @observable
  athleteId: string = '';
  @observable
  isAdmin: boolean = false;
  @observable
  username: string = '';
  @observable
  profileImage?: Media;
  @observable
  totalCollectedPoints: number = 0;
  @observable
  team?: RouteChallengeTeam;
  @observable
  challenge?: RouteChallenge;
  @observable
  athlete?: Athlete;

  constructor(json?: Partial<RouteChallengeTeamMemberJson>) {
    super(json);
    if (json) {
      this.teamId = json.teamId ?? '';
      this.challengeId = json.challengeId ?? '';
      this.gymId = json.gymId ?? '';
      this.customerId = json.customerId ?? '';
      this.athleteId = json.athleteId ?? '';
      this.isAdmin = json.isAdmin ?? false;
      this.username = json.username ?? '';
      this.totalCollectedPoints = json.totalCollectedPoints ?? 0;
      this.profileImage = json.profileImage ? new Media(json.profileImage) : undefined;
    }

    onBecomeObserved(this, 'team', () => {
      if (!this.team) {
        RouteChallengeTeam.findOne(this.teamId).then((team) => {
          runInAction(() => (this.team = team));
        });
      }
    });

    onBecomeObserved(this, 'challenge', () => {
      if (!this.challenge) {
        RouteChallenge.findOne(this.challengeId).then((challenge) => {
          runInAction(() => (this.challenge = challenge));
        });
      }
    });

    onBecomeObserved(this, 'athlete', () => {
      if (!this.athlete) {
        Athlete.get(this.athleteId).then((athlete) => {
          runInAction(() => (this.athlete = athlete));
        });
      }
    });
  }

  listTransactions(): Promise<any[]> {
    return HttpBackend.get(`/coach-program/engagement/route-challenges/teams/admin/${this.teamId}/members/${this.id}/transactions`);
  }

  static find(request?: Partial<RouteChallengeTeamMemberRequest>): Promise<RouteChallengeTeamMember[]> {
    return HttpBackend.get('/coach-program/engagement/route-challenges/teams/admin/members', request).then((response) =>
      response.map((r) => new RouteChallengeTeamMember(r)),
    );
  }

  static count(request?: Partial<RouteChallengeTeamMemberRequest>): Promise<number> {
    return HttpBackend.get('/coach-program/engagement/route-challenges/teams/admin/members/count', request);
  }
}
