/**
 * Created by neo on 02.08.22.
 */
import { LocalizedArrayEntity, LocalizedArrayEntityJson } from '../LocalizedArrayEntity';
import { EarnModelTemplateCategory, EarnModelTemplateCategoryJson } from './EarnModelTemplateCategory';
import { observable, onBecomeObserved, runInAction } from 'mobx';
import { Pageable } from '../Pageable';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { Gym } from '../Gym/Gym';

export type EarnModelTemplateQueryRequest = Pageable & {
  gymId: string;
};

export type EarnModelTemplateJson = LocalizedArrayEntityJson & {
  templateName: string;
  gymId?: string;
  categories: EarnModelTemplateCategoryJson[];
  activeFrom?: string;
  activeUntil?: string;
};

export class EarnModelTemplate extends LocalizedArrayEntity {
  @observable
  templateName = '';
  @observable
  categories: EarnModelTemplateCategory[] = [];
  @observable
  gymId?: string;
  @observable
  activeFrom?: Date;
  @observable
  activeUntil?: Date;
  @observable
  gym?: Gym;

  constructor(json?: Partial<EarnModelTemplateJson>) {
    super(json);
    if (json) {
      this.templateName = json.templateName ?? '';
      this.categories = (json.categories ?? []).map((c) => new EarnModelTemplateCategory(c));
      this.activeFrom = json.activeFrom ? new Date(json.activeFrom) : undefined;
      this.activeUntil = json.activeUntil ? new Date(json.activeUntil) : undefined;
      this.gymId = json.gymId;
    }

    onBecomeObserved(this, 'gym', () => this.fetchGym());
  }

  fetchGym() {
    if (!this.gym && this.gymId) {
      Gym.get(this.gymId).then((res) => runInAction(() => (this.gym = res)));
    }
  }

  toJS(newId: boolean = false): EarnModelTemplateJson {
    return Object.assign(super.toJS(newId), {
      templateName: this.templateName,
      categories: this.categories.map((c) => c.toJS(newId)),
      gymId: this.gymId,
      activeFrom: this.activeFrom?.toISOString(),
      activeUntil: this.activeUntil?.toISOString(),
    });
  }

  save(): Promise<EarnModelTemplate> {
    return HttpBackend.post(`/coach-program/engagement/earn/template/admin`, this.toJS()).then(() => this);
  }

  copy(): EarnModelTemplate {
    return new EarnModelTemplate(this.toJS(true));
  }

  static find(request?: Partial<EarnModelTemplateQueryRequest>): Promise<EarnModelTemplate[]> {
    return HttpBackend.get(`/coach-program/engagement/earn/template/admin`, request).then((res) =>
      (res ?? []).map((r) => new EarnModelTemplate(r)),
    );
  }

  static count(request?: Partial<EarnModelTemplateQueryRequest>): Promise<number> {
    return HttpBackend.get(`/coach-program/engagement/earn/template/admin/count`, request);
  }

  static findOne(id: string): Promise<EarnModelTemplate> {
    return HttpBackend.get(`/coach-program/engagement/earn/template/admin/${id}`).then((res) => new EarnModelTemplate(res));
  }
}
