import React, { useState, useRef, useCallback, useEffect } from 'react';
import { observer, Observer } from 'mobx-react';
import { AutoComplete, Table, Pagination, Button, Modal } from 'antd';
import dayjs from 'dayjs';
import { Athlete } from '../../Model/Athlete/Athlete';
import { PageResult } from '../../Model/PageResult';
import { SingleColRow } from '../../Components/SingleColRow';
import { useLocation, useNavigate } from 'react-router-dom';
import { runInAction, toJS } from 'mobx';
import { useRootStore } from '../../Store/useRootStore';
import { Container } from 'reactstrap';

export type AthleteListScreenProps = {};

export const AthleteListScreen: React.FC<AthleteListScreenProps> = observer(() => {
  const { authentication } = useRootStore();
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const initialQuery = searchParams.get('query') ?? '';
  const initialPage = Number(searchParams.get('page') ?? '0');
  const initialSortField = searchParams.get('sortField') ?? 'createdAt';
  const initialSortOrder = (searchParams.get('sortOrder') as 'ASC' | 'DESC') || 'DESC';

  const [query, setQuery] = useState(initialQuery);
  const [result, setResult] = useState(new PageResult<Athlete>());
  const [showCreateAthleteModal, setShowCreateAthleteModal] = useState(false);
  const [oauthToken, setOauthToken] = useState<any>();
  const [pageSize, setPageSize] = useState(20);
  const [autocompleteOptions, setAutocompleteOptions] = useState<{ value: string; label: React.ReactNode }[]>([]);
  const [sortField, setSortField] = useState(initialSortField);
  const [sortOrder, setSortOrder] = useState<'ASC' | 'DESC'>(initialSortOrder);
  const [previewImage, setPreviewImage] = useState<string | undefined>();

  const debounce = useRef<any>(undefined);
  const updateDebounce = useRef<any>(undefined);

  const updateSearchParams = useCallback(
    (q: string, page: number = 0, sField: string = sortField, sOrder: 'ASC' | 'DESC' = sortOrder) => {
      if (debounce.current) clearTimeout(debounce.current);
      debounce.current = setTimeout(() => {
        const params = new URLSearchParams(location.search);
        params.set('query', q);
        params.set('page', page.toString());
        params.set('sortField', sField);
        params.set('sortOrder', sOrder);
        navigate(
          {
            pathname: location.pathname,
            search: params.toString(),
          },
          { replace: true },
        );
      }, 300);
    },
    [location, navigate, sortField, sortOrder],
  );

  const loadAthletes = useCallback(
    (
      q: string,
      page: number = 0,
      sField: string = sortField,
      sOrder: 'ASC' | 'DESC' = sortOrder,
      size: number = pageSize,
    ) => {
      const params = Object.assign(
        { page, size, sort: `${sField},${sOrder}` },
        Object.fromEntries(new URLSearchParams(location.search)),
        { query: q },
      );
      PageResult.execute(Athlete.find(params), Athlete.count(toJS(params)), page, size).then((res) => {
        setResult(res);
        const options = res.content.map((athlete) => ({
          value: athlete.id,
          label: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {athlete.profilePicture?.url ? (
                <img
                  src={athlete.profilePicture.url}
                  alt="Profile"
                  style={{
                    width: 50,
                    height: 50,
                    borderRadius: '50%',
                    marginRight: 8,
                    objectFit: 'cover',
                  }}
                />
              ) : (
                <span style={{ marginRight: 8 }}>No Image</span>
              )}
              <div>
                <div>{athlete.fullName}</div>
                <div style={{ color: 'gray', fontSize: 12 }}>{athlete.user?.email}</div>
              </div>
            </div>
          ),
        }));
        setAutocompleteOptions(options);
      });
    },
    [location.search, pageSize, sortField, sortOrder],
  );

  useEffect(() => {
    if (updateDebounce.current) clearTimeout(updateDebounce.current);
    updateDebounce.current = setTimeout(() => {
      loadAthletes(query, initialPage, sortField, sortOrder);
    }, 300);
  }, [query, initialPage, sortField, sortOrder, loadAthletes]);

  const handleSearch = (value: string) => {
    setQuery(value);
    updateSearchParams(value, 0);
  };

  // Update handlePageChange to pass the new page size immediately.
  const handlePageChange = (newPage: number, newPageSize?: number) => {
    const effectiveSize = newPageSize && newPageSize !== pageSize ? newPageSize : pageSize;
    if (newPageSize && newPageSize !== pageSize) {
      setPageSize(newPageSize);
    }
    updateSearchParams(query, newPage - 1);
    loadAthletes(query, newPage - 1, sortField, sortOrder, effectiveSize);
  };

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    if (sorter.order) {
      const order = sorter.order === 'ascend' ? 'ASC' : 'DESC';
      setSortField(sorter.field);
      setSortOrder(order);
      updateSearchParams(query, result.number, sorter.field, order);
      loadAthletes(query, result.number, sorter.field, order);
    }
  };

  const handleCreate = () => {
    setShowCreateAthleteModal(true);
  };

  const handleSelect = (selectedId: string) => {
    navigate(`/athlete/${selectedId}`);
  };

  const handleCloseCreateAthleteModal = (athlete?: Athlete) => {
    setShowCreateAthleteModal(false);
    if (athlete) {
      runInAction(() => result.content.unshift(athlete));
    }
  };

  const handleRemove = (athlete: Athlete) => {
    Modal.confirm({
      title: `Do you really want to delete ${athlete.fullName || athlete.user.email}?`,
      onOk() {
        athlete.remove();
      },
    });
  };

  const columns = [
    {
      title: 'Profile Picture',
      dataIndex: 'profilePicture',
      key: 'profilePicture',
      render: (_: any, record: Athlete) => (
        <Observer>
          {() =>
            record.profilePicture?.url ? (
              <img
                src={record.profilePicture.url}
                alt="Profile"
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: '50%',
                  objectFit: 'cover',
                  cursor: 'pointer',
                }}
                onClick={() => setPreviewImage(record.profilePicture?.url)}
              />
            ) : (
              <span>No Image</span>
            )
          }
        </Observer>
      ),
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      render: (_: any, record: Athlete) => (
        <a onClick={() => navigate(`/athlete/${record.id}`)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
          {record.id}
        </a>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'fullName',
      sorter: true,
      render: (_: any, record: Athlete) => (
        <a onClick={() => navigate(`/athlete/${record.id}`)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
          {record.fullName}
        </a>
      ),
    },
    {
      title: 'User',
      dataIndex: ['user', 'email'],
      key: 'email',
      render: (_: any, record: Athlete) => <span>{record.user?.email}</span>,
    },
    {
      title: 'Companies',
      key: 'gyms',
      render: (_: any, record: Athlete) => (
        <Observer>
          {() =>
            record.gyms && record.gyms.length > 0 ? (
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
                {record.gyms.map((gym) => (
                  <a
                    key={gym.id}
                    href={`/infrastructure/gym/${gym.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      padding: '2px 6px',
                      background: '#f0f0f0',
                      borderRadius: 4,
                      textDecoration: 'none',
                      color: '#000',
                    }}
                  >
                    {gym.name}
                  </a>
                ))}
              </div>
            ) : (
              <span>No Companies</span>
            )
          }
        </Observer>
      ),
    },
    {
      title: 'Last Seen',
      dataIndex: 'lastSeen',
      key: 'lastSeen',
      render: (_: any, record: Athlete) => (
        <Observer>
          {() => (
            <span>{record.lastActivity?.updatedAt ? dayjs(record.lastActivity.updatedAt).format('LLLL') : '-'}</span>
          )}
        </Observer>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      render: (_: any, record: Athlete) => <span>{dayjs(record.createdAt).format('LLLL')}</span>,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record: Athlete) =>
        authentication.isOneAboveAll ? (
          <>
            <Button type="link" onClick={() => handleRemove(record)} style={{ color: 'red' }}>
              Delete
            </Button>
          </>
        ) : null,
    },
  ];

  // A helper to render the pagination with total count.
  const renderPagination = (current: number, total: number) => (
    <SingleColRow style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '16px 0' }}>
      {/* Using showTotal to display the total number of users */}
      <Pagination
        current={current}
        total={total}
        pageSize={pageSize}
        showSizeChanger
        pageSizeOptions={['10', '20', '50', '100']}
        showTotal={(total) => `Total users: ${total}`}
        onChange={handlePageChange}
        onShowSizeChange={(current, size) => handlePageChange(current, size)}
      />
    </SingleColRow>
  );

  return (
    <Container className="mt-3">
      <SingleColRow>
        <h1 style={{ marginBottom: '16px' }}>Athletes List</h1>
      </SingleColRow>
      <SingleColRow style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <AutoComplete
          style={{ flex: 1, marginRight: '8px' }}
          placeholder="Search athletes..."
          options={autocompleteOptions}
          onSelect={handleSelect}
          onSearch={handleSearch}
          value={query}
          onChange={(value) => setQuery(value)}
        />
        <Button type="primary" onClick={handleCreate}>
          Create New Athlete
        </Button>
      </SingleColRow>
      {/* Top Pagination */}
      {renderPagination(result.number + 1, result.totalElements || 0)}
      <SingleColRow>
        <Table<Athlete>
          rowKey="id"
          columns={columns}
          dataSource={result.content.slice()}
          pagination={false}
          bordered
          size="middle"
          onChange={handleTableChange}
        />
      </SingleColRow>
      {/* Bottom Pagination */}
      {renderPagination(result.number + 1, result.totalElements || 0)}
      {showCreateAthleteModal && <div>{/* Modal implementation goes here */}</div>}
      {previewImage && (
        <Modal visible={true} footer={null} onCancel={() => setPreviewImage(undefined)}>
          {/* Image preview content */}
        </Modal>
      )}
    </Container>
  );
});
