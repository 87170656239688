/**
 * Created by neo on 14.11.2023
 */
import { LocalizedArrayEntity, LocalizedArrayEntityJson } from '../../LocalizedArrayEntity';
import { action, observable, onBecomeObserved } from 'mobx';
import { HttpBackend } from '../../../Services/Http/HttpBackend';
import { Pageable } from '../../Pageable';
import { RouteChallengeLocation, RouteChallengeLocationJson } from './RouteChallengeLocation';
import { Media, MediaJson } from '../../Media/Media';
import { MapRegion, MapRegionJson } from './MapRegion';
import { RouteChallengeWalkthroughEntry, RouteChallengeWalkthroughEntryJson } from './RouteChallengeWalkthroughEntry';
import { Gym } from '../../Gym/Gym';
import dayjs from 'dayjs';

export type RouteChallengeJson = LocalizedArrayEntityJson & {
  gymId?: string;
  canCreateTeams: boolean;
  maxMembersPerTeam: number;
  minMembersPerTeam: number;
  totalCollectedPoints: number;
  startDateTime: string;
  endDateTime: string;
  displayStartDateTime: string;
  displayEndDateTime: string;
  locations: RouteChallengeLocationJson[];
  started: boolean;
  ended: boolean;
  images: MediaJson[];
  mapCenter?: MapRegionJson;
  beforeStartWalkthroughEntries: RouteChallengeWalkthroughEntryJson[];
  afterEndWalkthroughEntries: RouteChallengeWalkthroughEntryJson[];
  difficultyFactor: number;
};

export class RouteChallenge extends LocalizedArrayEntity {
  @observable
  gymId?: string;
  @observable
  canCreateTeams = true;
  @observable
  maxMembersPerTeam = 4;
  @observable
  minMembersPerTeam = 1;
  @observable
  totalCollectedPoints = 0;
  @observable
  startDateTime = dayjs().add(2, 'week').toDate();
  @observable
  endDateTime = dayjs().add(6, 'week').toDate();
  @observable
  displayStartDateTime = new Date();
  @observable
  displayEndDateTime = new Date();
  @observable
  locations: RouteChallengeLocation[] = [];
  @observable
  started = false;
  @observable
  ended = false;
  @observable
  images: Media[] = [];
  @observable
  mapCenter = new MapRegion();
  @observable
  beforeStartWalkthroughEntries: RouteChallengeWalkthroughEntry[] = [];
  @observable
  afterEndWalkthroughEntries: RouteChallengeWalkthroughEntry[] = [];
  @observable
  difficultyFactor = 0.5;
  @observable
  gym?: Gym;

  constructor(json?: Partial<RouteChallengeJson>) {
    super(json);
    if (json) {
      this.gymId = json.gymId ?? undefined;
      this.canCreateTeams = json.canCreateTeams ?? true;
      this.maxMembersPerTeam = json.maxMembersPerTeam ?? 4;
      this.minMembersPerTeam = json.minMembersPerTeam ?? 1;
      this.totalCollectedPoints = json.totalCollectedPoints ?? 0;
      this.startDateTime = json.startDateTime ? new Date(json.startDateTime) : dayjs().add(2, 'week').toDate();
      this.endDateTime = json.endDateTime ? new Date(json.endDateTime) : dayjs().add(6, 'week').toDate();
      this.displayStartDateTime = json.displayStartDateTime ? new Date(json.displayStartDateTime) : new Date();
      this.displayEndDateTime = json.displayEndDateTime ? new Date(json.displayEndDateTime) : new Date();
      this.locations = json.locations ? json.locations.map((js) => new RouteChallengeLocation(js)) : [];
      this.started = json.started ?? false;
      this.ended = json.ended ?? false;
      this.images = json.images?.map((image) => new Media(image)) ?? [];
      this.mapCenter = new MapRegion(json.mapCenter);
      this.beforeStartWalkthroughEntries =
        json.beforeStartWalkthroughEntries?.map((entry) => new RouteChallengeWalkthroughEntry(entry)) ?? [];
      this.afterEndWalkthroughEntries =
        json.afterEndWalkthroughEntries?.map((entry) => new RouteChallengeWalkthroughEntry(entry)) ?? [];
      this.difficultyFactor = json.difficultyFactor ?? 0.5;
    }

    onBecomeObserved(this, 'gym', () => {
      if (this.gymId && !this.gym) {
        Gym.get(this.gymId).then((gym) => (this.gym = gym));
      }
    });
  }

  toJS(newId: boolean = false): LocalizedArrayEntityJson {
    return Object.assign(super.toJS(newId), {
      gymId: this.gymId,
      canCreateTeams: this.canCreateTeams,
      maxMembersPerTeam: this.maxMembersPerTeam,
      minMembersPerTeam: this.minMembersPerTeam,
      totalCollectedPoints: this.totalCollectedPoints,
      startDateTime: this.startDateTime.toISOString(),
      endDateTime: this.endDateTime.toISOString(),
      displayStartDateTime: this.displayStartDateTime.toISOString(),
      displayEndDateTime: this.displayEndDateTime.toISOString(),
      locations: this.locations.map((location) => location.toJS(newId)),
      images: this.images.map((image) => image.toJS()),
      mapCenter: this.mapCenter?.toJS(),
      beforeStartWalkthroughEntries: this.beforeStartWalkthroughEntries.map((entry) => entry.toJS(newId)),
      afterEndWalkthroughEntries: this.afterEndWalkthroughEntries.map((entry) => entry.toJS(newId)),
      difficultyFactor: this.difficultyFactor,
    });
  }

  copy(): RouteChallenge {
    return new RouteChallenge(this.toJS(true));
  }

  @action
  createLocation(atIndex?: number, params?: Partial<RouteChallengeLocationJson>): RouteChallengeLocation {
    const location = new RouteChallengeLocation({
      name: [{ lang: 'en', value: 'New Location' }],
      ...(params ?? {}),
      challengeId: this.id,
    });
    if (atIndex !== undefined && atIndex >= 0 && atIndex <= this.locations.length) {
      this.locations.splice(atIndex, 0, location);
    } else {
      this.locations.push(location);
    }
    return location;
  }

  save(): Promise<RouteChallenge> {
    return HttpBackend.post(`/coach-program/engagement/route-challenges/admin`, this.toJS()).then(() => this);
  }

  @action
  start(): Promise<RouteChallenge> {
    if (!this.started) {
      this.started = true;
      return HttpBackend.post(`/coach-program/engagement/route-challenges/admin/${this.id}/start`).then(() => this);
    }
    return Promise.resolve(this);
  }

  @action
  end(): Promise<RouteChallenge> {
    if (this.started && !this.ended) {
      this.ended = true;
      return HttpBackend.post(`/coach-program/engagement/route-challenges/admin/${this.id}/end`).then(() => this);
    }
    return Promise.resolve(this);
  }

  delete(): Promise<RouteChallenge> {
    return HttpBackend.delete(`/coach-program/engagement/route-challenges/admin/${this.id}`).then(() => this);
  }

  static find(request?: Partial<Pageable & { gymId: string }>): Promise<RouteChallenge[]> {
    return HttpBackend.get(`/coach-program/engagement/route-challenges/admin`, request).then((json) =>
      json.map((entry) => new RouteChallenge(entry)),
    );
  }

  static count(): Promise<number> {
    return HttpBackend.get(`/coach-program/engagement/route-challenges/admin/count`);
  }

  static findOne(id: string): Promise<RouteChallenge> {
    return HttpBackend.get(`/coach-program/engagement/route-challenges/admin/${id}`).then((json) => new RouteChallenge(json));
  }
}
